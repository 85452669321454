// @flow
import { CredentialsActionTypes } from './constants';
import config from '../../../config';
import { APICore } from "../../../helpers/api/apiCore";
import services from "../../../constants/services";

const INIT_STATE = {
    loading: false,
    Git: {
        loading: false,
    },
    Unity: {
        loading: false,
    },
    Meta: {
        loading: false,
    },
    Google: {
        PlayStore: {
            loading: false,
        },
        Keystore: {
            loading: false,
        },
    },
    Apple: {
        AppStore: {
            loading: false,
        },
        Certificate: {
            loading: false,
        },
        Provision: {
            loading: false,
        },
    },
};

type CredentialsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Credentials = (state: State = INIT_STATE, action: CredentialsAction): any => {
    if(config.DEBUG === true && Object.values(CredentialsActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case CredentialsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CredentialsActionTypes.CREDENTIALS_VALIDATE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        validated: true,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_INFO: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = false;
                    gitState.gitData = action.payload.data;
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_ADD: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = false;
                    gitState.gitAdded = true;
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_EDIT: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = false;
                    gitState.gitEdited = true;
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_SYNC: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = false;
                    gitState.gitSynced = true;
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_REMOVE: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = false;
                    gitState.gitRemoved = true;
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_UNITY_INFO: {
                    const unityState = state.Unity ?? {};
                    unityState.loading = false;
                    unityState.error = false;
                    unityState.unityData = action.payload.data;
                    return {
                        ...state,
                        Unity: unityState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_UNITY_ADD: {
                    const unityState = state.Unity ?? {};
                    unityState.loading = false;
                    unityState.error = false;
                    unityState.unityAdded = true;
                    return {
                        ...state,
                        Unity: unityState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_UNITY_EDIT: {
                    const unityState = state.Unity ?? {};
                    unityState.loading = false;
                    unityState.error = false;
                    unityState.unityEdited = true;
                    return {
                        ...state,
                        Unity: unityState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_UNITY_REMOVE: {
                    const unityState = state.Unity ?? {};
                    unityState.loading = false;
                    unityState.error = false;
                    unityState.unityRemoved = true;
                    return {
                        ...state,
                        Unity: unityState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_META_INFO: {
                    const metaState = state.Meta ?? {};
                    metaState.loading = false;
                    metaState.error = false;
                    metaState.metaData = action.payload.data;
                    return {
                        ...state,
                        Meta: metaState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_META_ADD: {
                    const metaState = state.Meta ?? {};
                    metaState.loading = false;
                    metaState.error = false;
                    metaState.metaAdded = true;
                    return {
                        ...state,
                        Meta: metaState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_META_EDIT: {
                    const metaState = state.Meta ?? {};
                    metaState.loading = false;
                    metaState.error = false;
                    metaState.metaEdited = true;
                    return {
                        ...state,
                        Meta: metaState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_META_REMOVE: {
                    const metaState = state.Meta ?? {};
                    metaState.loading = false;
                    metaState.error = false;
                    metaState.metaRemoved = true;
                    return {
                        ...state,
                        Meta: metaState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_INFO: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.playStoreData = action.payload.data;
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_ADD: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.playStoreAdded = true;
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_EDIT: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.playStoreEdited = true;
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_SYNC: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.playStoreSynced = true;
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_REMOVE: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.playStoreRemoved = true;
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_INFO: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.keystoreData = action.payload.data;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_ADD: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.keystoreAdded = true;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_EDIT: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.keystoreEdited = true;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_PASSWORD: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.keystorePasswordEdited = true;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_SYNC: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.keystoreSynced = true;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_REMOVE: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.keystoreRemoved = true;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.appStoreData = action.payload.data;
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.appStoreAdded = true;
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_EDIT: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.appStoreEdited = true;
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_SYNC: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.appStoreSynced = true;
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.appStoreRemoved = true;
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.certificateData = action.payload.data;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.certificateAdded = true;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_EDIT: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.certificateEdited = true;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_SYNC: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.certificateSynced = true;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.certificateRemoved = true;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Provision ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.provisionData = action.payload.data;
                    return {...state, Apple: {...state.Apple, Provision: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Provision ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.provisionAdded = true;
                    return {...state, Apple: {...state.Apple, Provision: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Provision ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.provisionRemoved = true;
                    return {...state, Apple: {...state.Apple, Provision: tmpState}};
                }
                default:
                    return { ...state };
            }

        case CredentialsActionTypes.API_RESPONSE_ERROR:
            if(action.payload.error.includes("CRE-E-UV-AUW-"))
            {
                const api = new APICore();
                api.setServiceAccess(services.Credentials, null);
            }
            switch (action.payload.actionType) {
                case CredentialsActionTypes.CREDENTIALS_VALIDATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        validated: false,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_INFO: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = action.payload.error;
                    gitState.gitData = [];
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_ADD: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = action.payload.error;
                    gitState.gitAdded = false;
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_EDIT: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = action.payload.error;
                    gitState.gitEdited = false;
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_SYNC: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = action.payload.error;
                    gitState.gitSynced = false;
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GIT_REMOVE: {
                    const gitState = state.Git ?? {};
                    gitState.loading = false;
                    gitState.error = action.payload.error;
                    gitState.gitRemoved = false;
                    return {
                        ...state,
                        Git: gitState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_UNITY_INFO: {
                    const unityState = state.Unity ?? {};
                    unityState.loading = false;
                    unityState.error = action.payload.error;
                    unityState.unityData = [];
                    return {
                        ...state,
                        Unity: unityState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_UNITY_ADD: {
                    const unityState = state.Unity ?? {};
                    unityState.loading = false;
                    unityState.error = action.payload.error;
                    unityState.unityAdded = false;
                    return {
                        ...state,
                        Unity: unityState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_UNITY_EDIT: {
                    const unityState = state.Unity ?? {};
                    unityState.loading = false;
                    unityState.error = action.payload.error;
                    unityState.unityEdited = false;
                    return {
                        ...state,
                        Unity: unityState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_UNITY_REMOVE: {
                    const unityState = state.Unity ?? {};
                    unityState.loading = false;
                    unityState.error = action.payload.error;
                    unityState.unityRemoved = false;
                    return {
                        ...state,
                        Unity: unityState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_META_INFO: {
                    const metaState = state.Meta ?? {};
                    metaState.loading = false;
                    metaState.error = action.payload.error;
                    metaState.metaData = [];
                    return {
                        ...state,
                        Meta: metaState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_META_ADD: {
                    const metaState = state.Meta ?? {};
                    metaState.loading = false;
                    metaState.error = action.payload.error;
                    metaState.metaAdded = false;
                    return {
                        ...state,
                        Meta: metaState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_META_EDIT: {
                    const metaState = state.Meta ?? {};
                    metaState.loading = false;
                    metaState.error = action.payload.error;
                    metaState.metaEdited = false;
                    return {
                        ...state,
                        Meta: metaState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_META_REMOVE: {
                    const metaState = state.Meta ?? {};
                    metaState.loading = false;
                    metaState.error = action.payload.error;
                    metaState.metaRemoved = false;
                    return {
                        ...state,
                        Meta: metaState,
                    };
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_INFO: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.playStoreData = [];
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_ADD: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.playStoreAdded = false;
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_EDIT: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.playStoreEdited = false;
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_SYNC: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.playStoreSynced = false;
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_REMOVE: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.PlayStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.playStoreRemoved = false;
                    return {...state, Google: {...state.Google, PlayStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGL_KEYSTORE_INFO: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.keystoreData = [];
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_ADD: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.keystoreAdded = false;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_EDIT: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.keystoreEdited = false;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_PASSWORD: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.keystorePasswordEdited = false;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_SYNC: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.keystoreSynced = false;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_REMOVE: {
                    let tmpState = state.Google ?? {};
                    tmpState = tmpState.Keystore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.keystoreRemoved = false;
                    return {...state, Google: {...state.Google, Keystore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.appStoreData = [];
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.appStoreAdded = false;
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_EDIT: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.appStoreEdited = false;
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_SYNC: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.appStoreSynced = false;
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.AppStore ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.appStoreRemoved = false;
                    return {...state, Apple: {...state.Apple, AppStore: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.certificateData = [];
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.certificateAdded = false;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_EDIT: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.certificateEdited = false;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_SYNC: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.certificateSynced = false;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.certificateRemoved = false;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Provision ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.provisionData = [];
                    return {...state, Apple: {...state.Apple, Provision: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Provision ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.provisionAdded = false;
                    return {...state, Apple: {...state.Apple, Provision: tmpState}};
                }
                case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Provision ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.provisionRemoved = false;
                    return {...state, Apple: {...state.Apple, Provision: tmpState}};
                }
                default:
                    return { ...state };
            }
        case CredentialsActionTypes.CREDENTIALS_VALIDATE:
            return { ...state, loading: true, validated: false };
        case CredentialsActionTypes.CREDENTIALS_GIT_INFO: {
            const gitState = state.Git ?? {};
            gitState.loading = true;
            gitState.gitData = null;
            return {...state, Git: gitState};
        }
        case CredentialsActionTypes.CREDENTIALS_GIT_ADD: {
            const gitState = state.Git ?? {};
            gitState.loading = true;
            gitState.gitAdded = false;
            return {...state, Git: gitState};
        }
        case CredentialsActionTypes.CREDENTIALS_GIT_EDIT: {
            const gitState = state.Git ?? {};
            gitState.loading = true;
            gitState.gitEdited = false;
            return {...state, Git: gitState};
        }
        case CredentialsActionTypes.CREDENTIALS_GIT_SYNC:{
            const gitState = state.Git ?? {};
            gitState.loading = true;
            gitState.gitSynced = false;
            return {...state, Git: gitState};
        }
        case CredentialsActionTypes.CREDENTIALS_GIT_REMOVE:{
            const gitState = state.Git ?? {};
            gitState.loading = true;
            gitState.gitRemoved = false;
            return {...state, Git: gitState};
        }
        case CredentialsActionTypes.CREDENTIALS_UNITY_INFO: {
            const unityState = state.Unity ?? {};
            unityState.loading = true;
            unityState.unityData = null;
            return {...state, Unity: unityState};
        }
        case CredentialsActionTypes.CREDENTIALS_UNITY_ADD: {
            const unityState = state.Unity ?? {};
            unityState.loading = true;
            unityState.unityAdded = false;
            return {...state, Unity: unityState};
        }
        case CredentialsActionTypes.CREDENTIALS_UNITY_EDIT: {
            const unityState = state.Unity ?? {};
            unityState.loading = true;
            unityState.unityEdited = false;
            return {...state, Unity: unityState};
        }
        case CredentialsActionTypes.CREDENTIALS_UNITY_REMOVE:{
            const unityState = state.Unity ?? {};
            unityState.loading = true;
            unityState.unityRemoved = false;
            return {...state, Unity: unityState};
        }
        case CredentialsActionTypes.CREDENTIALS_META_INFO: {
            const metaState = state.Meta ?? {};
            metaState.loading = true;
            metaState.metaData = null;
            return {...state, Meta: metaState};
        }
        case CredentialsActionTypes.CREDENTIALS_META_ADD: {
            const metaState = state.Meta ?? {};
            metaState.loading = true;
            metaState.metaAdded = false;
            return {...state, Meta: metaState};
        }
        case CredentialsActionTypes.CREDENTIALS_META_EDIT: {
            const metaState = state.Meta ?? {};
            metaState.loading = true;
            metaState.metaEdited = false;
            return {...state, Meta: metaState};
        }
        case CredentialsActionTypes.CREDENTIALS_META_REMOVE:{
            const metaState = state.Meta ?? {};
            metaState.loading = true;
            metaState.metaRemoved = false;
            return {...state, Meta: metaState};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_INFO: {
            let tmpState = state.Google ?? {};
            tmpState = tmpState.PlayStore ?? {};
            tmpState.loading = true;
            tmpState.playStoreData = null;
            return {...state, Google: {...state.Google, PlayStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_ADD: {
            let tmpState = state.Google ?? {};
            tmpState = tmpState.PlayStore ?? {};
            tmpState.loading = true;
            tmpState.playStoreAdded = false;
            return {...state, Google: {...state.Google, PlayStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_EDIT: {
            let tmpState = state.Google ?? {};
            tmpState = tmpState.PlayStore ?? {};
            tmpState.loading = true;
            tmpState.playStoreEdited = false;
            return {...state, Google: {...state.Google, PlayStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_SYNC:{
            let tmpState = state.Google ?? {};
            tmpState = tmpState.PlayStore ?? {};
            tmpState.loading = true;
            tmpState.playStoreSynced = false;
            return {...state, Google: {...state.Google, PlayStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_REMOVE:{
            let tmpState = state.Google ?? {};
            tmpState = tmpState.PlayStore ?? {};
            tmpState.loading = true;
            tmpState.playStoreRemoved = false;
            return {...state, Google: {...state.Google, PlayStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_INFO: {
            let tmpState = state.Google ?? {};
            tmpState = tmpState.Keystore ?? {};
            tmpState.loading = true;
            tmpState.keystoreData = null;
            return {...state, Google: {...state.Google, Keystore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_ADD: {
            let tmpState = state.Google ?? {};
            tmpState = tmpState.Keystore ?? {};
            tmpState.loading = true;
            tmpState.keystoreAdded = false;
            return {...state, Google: {...state.Google, Keystore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_EDIT: {
            let tmpState = state.Google ?? {};
            tmpState = tmpState.Keystore ?? {};
            tmpState.loading = true;
            tmpState.keystoreEdited = false;
            return {...state, Google: {...state.Google, Keystore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_PASSWORD: {
            let tmpState = state.Google ?? {};
            tmpState = tmpState.Keystore ?? {};
            tmpState.loading = true;
            tmpState.keystorePasswordEdited = false;
            return {...state, Google: {...state.Google, Keystore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_SYNC:{
            let tmpState = state.Google ?? {};
            tmpState = tmpState.Keystore ?? {};
            tmpState.loading = true;
            tmpState.keystoreSynced = false;
            return {...state, Google: {...state.Google, Keystore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_REMOVE:{
            let tmpState = state.Google ?? {};
            tmpState = tmpState.Keystore ?? {};
            tmpState.loading = true;
            tmpState.keystoreRemoved = false;
            return {...state, Google: {...state.Google, Keystore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_INFO: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.AppStore ?? {};
            tmpState.loading = true;
            tmpState.appStoreData = null;
            return {...state, Apple: {...state.Apple, AppStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_ADD: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.AppStore ?? {};
            tmpState.loading = true;
            tmpState.appStoreAdded = false;
            return {...state, Apple: {...state.Apple, AppStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_EDIT: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.AppStore ?? {};
            tmpState.loading = true;
            tmpState.appStoreEdited = false;
            return {...state, Apple: {...state.Apple, AppStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_SYNC:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.AppStore ?? {};
            tmpState.loading = true;
            tmpState.appStoreSynced = false;
            return {...state, Apple: {...state.Apple, AppStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_REMOVE:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.AppStore ?? {};
            tmpState.loading = true;
            tmpState.appStoreRemoved = false;
            return {...state, Apple: {...state.Apple, AppStore: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_INFO: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Certificate ?? {};
            tmpState.loading = true;
            tmpState.certificateData = null;
            return {...state, Apple: {...state.Apple, Certificate: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_ADD: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Certificate ?? {};
            tmpState.loading = true;
            tmpState.certificateAdded = false;
            return {...state, Apple: {...state.Apple, Certificate: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_EDIT: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Certificate ?? {};
            tmpState.loading = true;
            tmpState.certificateEdited = false;
            return {...state, Apple: {...state.Apple, Certificate: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_SYNC:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Certificate ?? {};
            tmpState.loading = true;
            tmpState.certificateSynced = false;
            return {...state, Apple: {...state.Apple, Certificate: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_REMOVE:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Certificate ?? {};
            tmpState.loading = true;
            tmpState.certificateRemoved = false;
            return {...state, Apple: {...state.Apple, Certificate: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_INFO: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Provision ?? {};
            tmpState.loading = true;
            tmpState.provisionData = null;
            return {...state, Apple: {...state.Apple, Provision: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_ADD: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Provision ?? {};
            tmpState.loading = true;
            tmpState.provisionAdded = false;
            return {...state, Apple: {...state.Apple, Provision: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_REMOVE:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Provision ?? {};
            tmpState.loading = true;
            tmpState.provisionRemoved = false;
            return {...state, Apple: {...state.Apple, Provision: tmpState}};
        }
        case CredentialsActionTypes.CREDENTIALS_GIT_RESET:
            return {
                ...state,
                Git: {
                    loading: false,
                    error: false,
                    gitData: null,
                    gitAdded: false,
                    gitEdited: false,
                    gitSynced: false,
                    gitRemoved: false,
                },
            };
        case CredentialsActionTypes.CREDENTIALS_UNITY_RESET:
            return {
                ...state,
                Unity: {
                    loading: false,
                    error: false,
                    unityData: null,
                    unityAdded: false,
                    unityEdited: false,
                    unityRemoved: false,
                },
            };
        case CredentialsActionTypes.CREDENTIALS_META_RESET:
            return {
                ...state,
                Meta: {
                    loading: false,
                    error: false,
                    metaData: null,
                    metaAdded: false,
                    metaEdited: false,
                    metaRemoved: false,
                },
            };
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_RESET:
            return {
                ...state,
                Google: {
                    ...state.Google,
                    PlayStore: {
                        loading: false,
                        error: false,
                        playStoreData: null,
                        playStoreAdded: false,
                        playStoreEdited: false,
                        playStoreSynced: false,
                        playStoreRemoved: false,
                    },
                },
            };
        case CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_RESET:
            return {
                ...state,
                Google: {
                    ...state.Google,
                    Keystore: {
                        loading: false,
                        error: false,
                        keystoreData: null,
                        keystoreAdded: false,
                        keystoreEdited: false,
                        keystorePasswordEdited: false,
                        keystoreSynced: false,
                        keystoreRemoved: false,
                    },
                },
            };
        case CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_RESET:
            return {
                ...state,
                Apple: {
                    ...state.Apple,
                    AppStore: {
                        loading: false,
                        error: false,
                        appStoreData: null,
                        appStoreAdded: false,
                        appStoreEdited: false,
                        appStoreSynced: false,
                        appStoreRemoved: false,
                    },
                },
            };
        case CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_RESET:
            return {
                ...state,
                Apple: {
                    ...state.Apple,
                    Certificate: {
                        loading: false,
                        error: false,
                        certificateData: null,
                        certificateAdded: false,
                        certificateEdited: false,
                        certificateSynced: false,
                        certificateRemoved: false,
                    },
                },
            };
        case CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_RESET:
            return {
                ...state,
                Apple: {
                    ...state.Apple,
                    Provision: {
                        loading: false,
                        error: false,
                        provisionData: null,
                        provisionAdded: false,
                        provisionRemoved: false,
                    },
                },
            };
        case CredentialsActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                validated: false,
                Git: {
                    loading: false,
                    error: false,
                    gitData: null,
                    gitAdded: false,
                    gitEdited: false,
                    gitSynced: false,
                    gitRemoved: false,
                },
                Unity: {
                    loading: false,
                    error: false,
                    unityData: null,
                    unityAdded: false,
                    unityEdited: false,
                    unityRemoved: false,
                },
                Meta: {
                    loading: false,
                    error: false,
                    metaData: null,
                    metaAdded: false,
                    metaEdited: false,
                    metaRemoved: false,
                },
                Google: {
                    PlayStore: {
                        loading: false,
                        error: false,
                        playStoreData: null,
                        playStoreAdded: false,
                        playStoreEdited: false,
                        playStoreSynced: false,
                        playStoreRemoved: false,
                    },
                    Keystore: {
                        loading: false,
                        error: false,
                        keystoreData: null,
                        keystoreAdded: false,
                        keystoreEdited: false,
                        keystorePasswordEdited: false,
                        keystoreSynced: false,
                        keystoreRemoved: false,
                    },
                },
                Apple: {
                    AppStore: {
                        loading: false,
                        error: false,
                        appStoreData: null,
                        appStoreAdded: false,
                        appStoreEdited: false,
                        appStoreSynced: false,
                        appStoreRemoved: false,
                    },
                    Certificate: {
                        loading: false,
                        error: false,
                        certificateData: null,
                        certificateAdded: false,
                        certificateEdited: false,
                        certificateSynced: false,
                        certificateRemoved: false,
                    },
                    Provision: {
                        loading: false,
                        error: false,
                        provisionData: null,
                        provisionAdded: false,
                        provisionRemoved: false,
                    },
                },
            };
        default:
            return { ...state };
    }
};

export default Credentials;
