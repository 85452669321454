//@flow
import React from 'react';
import { Navigate } from 'react-router-dom';

import { LoadServiceComponent } from "./LoadServiceComponent";

import services from "../../../constants/services";

// Routes
import { CredentialsRoutesConstants } from '../../../constants/urls/routes';

// credentials
const Credentials = React.lazy(() => import('../../../pages/services/credentials/index'));

const Git = React.lazy(() => import('../../../pages/services/credentials/git/index'));
const AddGit = React.lazy(() => import('../../../pages/services/credentials/git/AddGit'));
const EditGit = React.lazy(() => import('../../../pages/services/credentials/git/EditGit'));
const SyncGit = React.lazy(() => import('../../../pages/services/credentials/git/SyncGit'));
const RemoveGit = React.lazy(() => import('../../../pages/services/credentials/git/RemoveGit'));

const Unity = React.lazy(() => import('../../../pages/services/credentials/unity/index'));
const AddUnity = React.lazy(() => import('../../../pages/services/credentials/unity/AddUnity'));
const EditUnity = React.lazy(() => import('../../../pages/services/credentials/unity/EditUnity'));
const RemoveUnity = React.lazy(() => import('../../../pages/services/credentials/unity/RemoveUnity'));

const Meta = React.lazy(() => import('../../../pages/services/credentials/meta/index'));
const AddMeta = React.lazy(() => import('../../../pages/services/credentials/meta/AddMeta'));
const EditMeta = React.lazy(() => import('../../../pages/services/credentials/meta/EditMeta'));
const RemoveMeta = React.lazy(() => import('../../../pages/services/credentials/meta/RemoveMeta'));

const PlayStore = React.lazy(() => import('../../../pages/services/credentials/google/playStore/index'));
const AddPlayStore = React.lazy(() => import('../../../pages/services/credentials/google/playStore/AddPlayStore'));
const EditPlayStore = React.lazy(() => import('../../../pages/services/credentials/google/playStore/EditPlayStore'));
const SyncPlayStore = React.lazy(() => import('../../../pages/services/credentials/google/playStore/SyncPlayStore'));
const RemovePlayStore = React.lazy(() => import('../../../pages/services/credentials/google/playStore/RemovePlayStore'));

const Keystore = React.lazy(() => import('../../../pages/services/credentials/google/keystore/index'));
const AddKeystore = React.lazy(() => import('../../../pages/services/credentials/google/keystore/AddKeystore'));
const EditKeystore = React.lazy(() => import('../../../pages/services/credentials/google/keystore/EditKeystore'));
const KeystorePasswords = React.lazy(() => import('../../../pages/services/credentials/google/keystore/KeystorePasswords'));
const SyncKeystore = React.lazy(() => import('../../../pages/services/credentials/google/keystore/SyncKeystore'));
const RemoveKeystore = React.lazy(() => import('../../../pages/services/credentials/google/keystore/RemoveKeystore'));

const AppStore = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/index'));
const AddAppStore = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/AddAppStore'));
const EditAppStore = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/EditAppStore'));
const SyncAppStore = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/SyncAppStore'));
const RemoveAppStore = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/RemoveAppStore'));

const ManageAppStore = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage'));
const ManageAppStoreCertificateList = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/certificates/CertificatesList'));
const ManageAppStoreCertificateAdd = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/certificates/AddCertificate'));
const ManageAppStoreCertificateRemove = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/certificates/RemoveCertificate'));
const ManageAppStoreDeviceList = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/devices/DevicesList'));
const ManageAppStoreDeviceAdd = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/devices/AddDevice'));
const ManageAppStoreDeviceToggle = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/devices/ToggleDevice'));
const ManageAppStoreBundleIdList = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/bundleIds/BundleIdsList'));
const ManageAppStoreBundleIdAdd = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/bundleIds/AddBundleId'));
const ManageAppStoreBundleIdRemove = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/bundleIds/RemoveBundleId'));
const ManageAppStoreProvisionProfileList = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/provisionProfiles/ProvisionProfilesList'));
const ManageAppStoreProvisionProfileAdd = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/provisionProfiles/AddProvisionProfile'));
const ManageAppStoreProvisionProfileImport = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/provisionProfiles/ImportProvisionProfile'));
const ManageAppStoreProvisionProfileRemove = React.lazy(() => import('../../../pages/services/credentials/apple/appStore/manage/provisionProfiles/RemoveProvisionProfile'));

const Certificate = React.lazy(() => import('../../../pages/services/credentials/apple/certificate/index'));
const AddCertificate = React.lazy(() => import('../../../pages/services/credentials/apple/certificate/AddCertificate'));
const EditCertificate = React.lazy(() => import('../../../pages/services/credentials/apple/certificate/EditCertificate'));
const SyncCertificate = React.lazy(() => import('../../../pages/services/credentials/apple/certificate/SyncCertificate'));
const RemoveCertificate = React.lazy(() => import('../../../pages/services/credentials/apple/certificate/RemoveCertificate'));
const Provision = React.lazy(() => import('../../../pages/services/credentials/apple/provision/index'));
const AddProvision = React.lazy(() => import('../../../pages/services/credentials/apple/provision/AddProvision'));
const RemoveProvision = React.lazy(() => import('../../../pages/services/credentials/apple/provision/RemoveProvision'));

const CredentialsRouter = [
    { path: CredentialsRoutesConstants.CredentialsURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={Credentials} />, },

    { path: CredentialsRoutesConstants.CredentialsGitURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={Git} />, },
    { path: CredentialsRoutesConstants.CredentialsGitAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={AddGit} />, },
    { path: CredentialsRoutesConstants.CredentialsGitEditURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGitURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGitEditURL+"/:gitId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={EditGit} />, },
    { path: CredentialsRoutesConstants.CredentialsGitSyncURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGitURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGitSyncURL+"/:gitId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={SyncGit} />, },
    { path: CredentialsRoutesConstants.CredentialsGitRemoveURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGitURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGitRemoveURL+"/:gitId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={RemoveGit} />, },

    { path: CredentialsRoutesConstants.CredentialsUnityURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={Unity} />, },
    { path: CredentialsRoutesConstants.CredentialsUnityAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={AddUnity} />, },
    { path: CredentialsRoutesConstants.CredentialsUnityEditURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsUnityURL} />, },
    { path: CredentialsRoutesConstants.CredentialsUnityEditURL+"/:unityId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={EditUnity} />, },
    { path: CredentialsRoutesConstants.CredentialsUnityRemoveURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsUnityURL} />, },
    { path: CredentialsRoutesConstants.CredentialsUnityRemoveURL+"/:unityId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={RemoveUnity} />, },

    { path: CredentialsRoutesConstants.CredentialsMetaURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={Meta} />, },
    { path: CredentialsRoutesConstants.CredentialsMetaAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={AddMeta} />, },
    { path: CredentialsRoutesConstants.CredentialsMetaEditURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsMetaURL} />, },
    { path: CredentialsRoutesConstants.CredentialsMetaEditURL+"/:metaId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={EditMeta} />, },
    { path: CredentialsRoutesConstants.CredentialsMetaRemoveURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsMetaURL} />, },
    { path: CredentialsRoutesConstants.CredentialsMetaRemoveURL+"/:metaId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={RemoveMeta} />, },

    { path: CredentialsRoutesConstants.CredentialsGoogleURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsURL} />, },

    { path: CredentialsRoutesConstants.CredentialsGooglePlayStoreURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={PlayStore} />, },
    { path: CredentialsRoutesConstants.CredentialsGooglePlayStoreAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={AddPlayStore} />, },
    { path: CredentialsRoutesConstants.CredentialsGooglePlayStoreEditURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGooglePlayStoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGooglePlayStoreEditURL+"/:playStoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={EditPlayStore} />, },
    { path: CredentialsRoutesConstants.CredentialsGooglePlayStoreSyncURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGooglePlayStoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGooglePlayStoreSyncURL+"/:playStoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={SyncPlayStore} />, },
    { path: CredentialsRoutesConstants.CredentialsGooglePlayStoreRemoveURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGooglePlayStoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGooglePlayStoreRemoveURL+"/:playStoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={RemovePlayStore} />, },

    { path: CredentialsRoutesConstants.CredentialsGoogleKeystoreURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={Keystore} />, },
    { path: CredentialsRoutesConstants.CredentialsGoogleKeystoreAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={AddKeystore} />, },
    { path: CredentialsRoutesConstants.CredentialsGoogleKeystoreEditURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGoogleKeystoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGoogleKeystoreEditURL+"/:keystoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={EditKeystore} />, },
    { path: CredentialsRoutesConstants.CredentialsGoogleKeystoreSyncURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGoogleKeystoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGoogleKeystoreSyncURL+"/:keystoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={SyncKeystore} />, },
    { path: CredentialsRoutesConstants.CredentialsGoogleKeystorePasswordsURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGoogleKeystoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGoogleKeystorePasswordsURL+"/:keystoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={KeystorePasswords} />, },
    { path: CredentialsRoutesConstants.CredentialsGoogleKeystoreRemoveURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsGoogleKeystoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsGoogleKeystoreRemoveURL+"/:keystoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={RemoveKeystore} />, },

    { path: CredentialsRoutesConstants.CredentialsAppleURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsURL} />, },

    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={AppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={AddAppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreEditURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleAppStoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreEditURL+"/:appStoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={EditAppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreSyncURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleAppStoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreSyncURL+"/:appStoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={SyncAppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreRemoveURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleAppStoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreRemoveURL+"/:appStoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={RemoveAppStore} />, },

    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleAppStoreURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageURL+"/:appStoreId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageCertificatesURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreCertificateList} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageCertificatesAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreCertificateAdd} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageCertificatesRemoveURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageCertificatesRemoveURL+"/:certificateId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreCertificateRemove} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageDevicesURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreDeviceList} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageDevicesAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreDeviceAdd} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageDevicesToggleURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageDevicesToggleURL+"/:deviceId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreDeviceToggle} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageBundleIdsURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreBundleIdList} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageBundleIdsAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreBundleIdAdd} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageBundleIdsRemoveURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageBundleIdsRemoveURL+"/:bundleId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreBundleIdRemove} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageProvisionProfilesURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreProvisionProfileList} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageProvisionProfilesAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreProvisionProfileAdd} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageProvisionProfilesImportURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageProvisionProfilesImportURL+"/:provisionProfileId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreProvisionProfileImport} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageProvisionProfilesRemoveURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStore} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleAppStoreManageProvisionProfilesRemoveURL+"/:provisionProfileId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={ManageAppStoreProvisionProfileRemove} />, },

    { path: CredentialsRoutesConstants.CredentialsAppleCertificateURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={Certificate} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleCertificateAddURL, element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={AddCertificate} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleCertificateEditURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleCertificateURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleCertificateEditURL+"/:certificateId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={EditCertificate} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleCertificateSyncURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleCertificateURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleCertificateSyncURL+"/:certificateId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={SyncCertificate} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleCertificateRemoveURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleCertificateURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleCertificateRemoveURL+"/:certificateId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={RemoveCertificate} />, },

    { path: CredentialsRoutesConstants.CredentialsAppleProvisionURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleCertificateURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleProvisionURL+"/:certificateId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={Provision} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleProvisionAddURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleCertificateURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleProvisionAddURL+"/:certificateId", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={AddProvision} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleProvisionRemoveURL, element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleCertificateURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleProvisionRemoveURL+"/:certificateId", element: <Navigate to={CredentialsRoutesConstants.CredentialsAppleCertificateURL} />, },
    { path: CredentialsRoutesConstants.CredentialsAppleProvisionRemoveURL+"/:certificateId/:provisionName", element: <LoadServiceComponent serviceId={services["Credentials"]} selectionLevel={"organization"} component={RemoveProvision} />, },
];

export { CredentialsRouter };
