import config from '../../../config';

const BaseURL = config.CREDENTIALS_URL +"/";
const CredentialsAPIConstants = {
    ValidateURL: BaseURL + "validate",

    GitInfoURL: BaseURL + "git",
    GitAddURL: BaseURL + "git/add",
    GitEditURL: BaseURL + "git/edit",
    GitSyncURL: BaseURL + "git/sync",
    GitRemoveURL: BaseURL + "git/remove",

    UnityInfoURL: BaseURL + "unity",
    UnityAddURL: BaseURL + "unity/add",
    UnityEditURL: BaseURL + "unity/edit",
    UnityRemoveURL: BaseURL + "unity/remove",

    MetaInfoURL: BaseURL + "meta",
    MetaAddURL: BaseURL + "meta/add",
    MetaEditURL: BaseURL + "meta/edit",
    MetaRemoveURL: BaseURL + "meta/remove",

    GooglePlayStoreInfoURL: BaseURL + "google/playstore",
    GooglePlayStoreAddURL: BaseURL + "google/playstore/add",
    GooglePlayStoreEditURL: BaseURL + "google/playstore/edit",
    GooglePlayStoreSyncURL: BaseURL + "google/playstore/sync",
    GooglePlayStoreRemoveURL: BaseURL + "google/playstore/remove",

    GoogleKeystoreInfoURL: BaseURL + "google/keystore",
    GoogleKeystoreAddURL: BaseURL + "google/keystore/add",
    GoogleKeystoreEditURL: BaseURL + "google/keystore/edit",
    GoogleKeystorePasswordURL: BaseURL + "google/keystore/password",
    GoogleKeystoreSyncURL: BaseURL + "google/keystore/sync",
    GoogleKeystoreRemoveURL: BaseURL + "google/keystore/remove",

    AppleAppStoreInfoURL: BaseURL + "apple/appstore",
    AppleAppStoreAddURL: BaseURL + "apple/appstore/add",
    AppleAppStoreEditURL: BaseURL + "apple/appstore/edit",
    AppleAppStoreSyncURL: BaseURL + "apple/appstore/sync",
    AppleAppStoreRemoveURL: BaseURL + "apple/appstore/remove",

    AppleCertificateInfoURL: BaseURL + "apple/certificate",
    AppleCertificateAddURL: BaseURL + "apple/certificate/add",
    AppleCertificateEditURL: BaseURL + "apple/certificate/edit",
    AppleCertificatePasswordURL: BaseURL + "apple/certificate/password",
    AppleCertificateSyncURL: BaseURL + "apple/certificate/sync",
    AppleCertificateRemoveURL: BaseURL + "apple/certificate/remove",

    AppleProvisionInfoURL: BaseURL + "apple/provision",
    AppleProvisionAddURL: BaseURL + "apple/provision/add",
    AppleProvisionRemoveURL: BaseURL + "apple/provision/remove",
};

export default CredentialsAPIConstants;
